export enum DailyDropBenefit {
  FiveHundredUsd = '500usd',
  OneHundredUsd = '100usd',
  FiftyUsd = '50usd',
  TenUSDOnboard = '10usd.onboard',
  Vibes = '100vibes',
}

export type DailyDropResult = {
  type: DailyDropBenefit;
  userId: string;
  email: string;
  received: boolean;
};

export type ApiGetDailyDropResultsResponse = {
  result: DailyDropResult[];
};
