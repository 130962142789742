import isEqual from 'lodash/isEqual';

import { ONCE } from 'src/types/once';

import { ModerationContentItem } from './types';

export const getUpdatedModerationContentItem = (
  category: string,
  level: ONCE.ContentDecisionCategoryLevelType,
  initialModerationContentItem: ModerationContentItem,
  stateModerationContentItem?: ModerationContentItem,
): ModerationContentItem => {
  const oldStateCategoryLevels = stateModerationContentItem?.categoryLevels;

  const updatedCategoryLevels = {
    ...oldStateCategoryLevels,
    [category]: level,
  };

  if (level === ONCE.ContentDecisionCategoryLevelType.Green) {
    delete updatedCategoryLevels[category];

    if (initialModerationContentItem.categoryLevels[category] === ONCE.ContentDecisionCategoryLevelType.Yellow) {
      updatedCategoryLevels[category] = ONCE.ContentDecisionCategoryLevelType.Yellow;
    }
  }

  const isModerated = !isEqual(initialModerationContentItem.categoryLevels, updatedCategoryLevels);

  return {
    ...initialModerationContentItem,
    ...stateModerationContentItem,
    isModerated,
    categoryLevels: updatedCategoryLevels,
  };
};
