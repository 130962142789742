export const genderOptions = [
  { text: 'Woman', value: 'woman' },
  { text: 'Man', value: 'man' },
  { text: 'Other Genders', value: 'otherGender' },
];

export const countriesOptions = [
  { text: 'US', value: 'US' },
  { text: 'Other Countries', value: 'otherCountry' },
];

export const campusesOptions = [{ text: 'USC', value: 'usc.edu' }];

export const allGenders = genderOptions.map((it) => it.value);

export const buttonUrlOptions = [
  {
    text: 'Feed',
    value: '/FeedTab',
  },
  {
    text: 'Profile Visitors',
    value: '/ProfileVisitorsTab',
  },
  {
    text: 'Likes',
    value: '/LikesTab',
  },
  {
    text: 'Chats List',
    value: '/ContactsTab',
  },
  {
    text: 'Account',
    value: '/AccountTab',
  },
  {
    text: 'Boost Profile',
    value: '/BoostProfileModal',
  },
  {
    text: 'Filters',
    value: '/Filters',
  },
  {
    text: 'Filters (Location)',
    value: '/FilterLocation',
  },
  {
    text: 'Filters (Looking For)',
    value: '/FilterSelect/type=looking-for',
  },
  {
    text: 'Filters (Relationship Goals)',
    value: '/FilterSelect/type=relationship-goals',
  },
  {
    text: 'Filters (Height)',
    value: '/FilterHeight',
  },
  {
    text: 'Edit Account',
    value: '/EditAccount',
  },
  {
    text: 'Edit Account (Name)',
    value: '/EditAccountText/type=name',
  },
  {
    text: 'Edit Account (Birthday)',
    value: '/EditAccountBirthday',
  },
  {
    text: 'Edit Account (Looking For)',
    value: '/EditAccountText/type=desired-relationship',
  },
  {
    text: 'Edit Account (Relationship Goals)',
    value: '/EditAccountSelect/type=relationship-goals',
  },
  {
    text: 'Edit Account (Your lifestyle / Interests)',
    value: '/EditAccountSelect/type=interests',
  },
  {
    text: 'Edit Account (Sexual Orientation)',
    value: '/EditAccountSelect/type=sexual-orientation',
  },
  {
    text: 'Edit Account (Sexual Identity)',
    value: '/EditAccountSelect/type=sexual-identity',
  },
  {
    text: 'Edit Account (Height)',
    value: '/EditAccountHeight',
  },
  {
    text: 'Buy Premium (PopularUsers)',
    value: '/BuyPremium/reason=like-popular',
  },
  {
    text: 'Buy Premium (LastSeen)',
    value: '/BuyPremium/reason=online',
  },
  {
    text: 'Buy Premium (ReadStatus)',
    value: '/BuyPremium/reason=delivery-status',
  },
  {
    text: 'Buy Premium (Cities)',
    value: '/BuyPremium/reason=cities',
  },
  {
    text: 'Buy Premium (ExtraLikes)',
    value: '/BuyPremium/reason=extra-likes',
  },
  {
    text: 'Buy Premium (Chatting with AI)',
    value: '/BuyPremium/reason=ai-Anna',
  },
  {
    text: 'Buy Premium (Special Offer 12m)',
    value: '/BuyPremiumSpecialOffer',
  },
  {
    text: 'Buy Vibes (Likes)',
    value: '/BuyVibes/reason=like',
  },
  {
    text: 'Buy Vibes (SuperLikes)',
    value: '/BuyVibes/reason=super-like',
  },
  {
    text: 'Buy Vibes (FlashMessages)',
    value: '/BuyVibes/reason=flash-message',
  },
  {
    text: 'Buy Vibes (Boost)',
    value: '/BuyVibes/reason=boost',
  },
  {
    text: 'Buy Vibes (FollowUps)',
    value: '/BuyVibes/reason=follow-up',
  },
  {
    text: 'Contact Support',
    value: '/ContactSupport',
  },
  {
    text: 'Share Link for Students',
    value: '/ShareFlureStudents',
  },
  {
    text: 'Daily Drop',
    value: '/DailyDrop',
  },
];
