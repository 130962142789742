import React, { useCallback } from 'react';
import clsx from 'clsx';

import { LabelText } from 'src/components/common/flure';
import { FlureChatWinner, MessageMeta } from 'src/components/FlureChat/types';
import { Gender } from 'src/types/user';
import { PhotoTransformation, useGetMediaSourceAuthed } from 'src/components/Moderation/utils/image-source';
import { DailyDropBenefit } from 'src/network/flure-daily-drop/types';

import noAvatarMale from './assets/no-avatar-mal.webp';
import noAvatarFemale from './assets/no-avatar-fem.webp';
import winnerCrown from './assets/winner-crown.webp';
import { chatAvatarSize, useStyles } from './styles';

type Props = {
  meta: MessageMeta;
};

const benefitTitleMap = {
  [DailyDropBenefit.FiveHundredUsd]: '$500',
  [DailyDropBenefit.OneHundredUsd]: '$100',
  [DailyDropBenefit.FiftyUsd]: '$50',
  [DailyDropBenefit.TenUSDOnboard]: '$10',
  [DailyDropBenefit.Vibes]: '',
};

const benefitEmojiMap = {
  [DailyDropBenefit.FiveHundredUsd]: ' 🏆',
  [DailyDropBenefit.OneHundredUsd]: ' 💰',
  [DailyDropBenefit.FiftyUsd]: ' 💸',
  [DailyDropBenefit.TenUSDOnboard]: ' 💸',
  [DailyDropBenefit.Vibes]: '',
};

export const WinnersListMessage = React.memo(({ meta }: Props) => {
  const { winnersListHeader, winnersListFooter, notAllPrizesFooter, winners = [] } = meta || {};
  const classes = useStyles();
  const getMediaSource = useGetMediaSourceAuthed();

  const renderWinner = useCallback(
    (winner: FlureChatWinner, index: number) => {
      const noAvatarImage = winner.gender === Gender.Male ? noAvatarMale : noAvatarFemale;
      const benefit = winner.type;
      const isGrandBenefit = benefit === DailyDropBenefit.FiveHundredUsd;
      const userAvatar = getMediaSource(
        winner.userId,
        winner.thumbnail,
        'image',
        true,
        chatAvatarSize,
        PhotoTransformation.DetectFace,
      );

      return (
        <div
          key={winner.userId}
          className={clsx([classes.winnerRow, index !== winners.length - 1 && classes.winnerBorder])}
        >
          <div className={classes.leftContainer}>
            {isGrandBenefit && <img className={classes.winnerCrown} src={winnerCrown} alt="crown" />}
            <img
              className={isGrandBenefit ? classes.grandWinnerAvatar : classes.winnerAvatar}
              src={userAvatar}
              alt="userPhoto"
              onError={(e) => {
                e.currentTarget.src = noAvatarImage;
              }}
            />
            <LabelText className={classes.winnerName} text={winner.name} />
          </div>
          <div className={classes.benefitTextRow}>
            <LabelText className={isGrandBenefit ? classes.largeText : undefined} text={benefitTitleMap[benefit]} />
            <LabelText
              className={clsx([classes.emoji, isGrandBenefit && classes.emojiMargin])}
              text={benefitEmojiMap[benefit]}
            />
          </div>
        </div>
      );
    },
    [
      classes.benefitTextRow,
      classes.emoji,
      classes.emojiMargin,
      classes.grandWinnerAvatar,
      classes.largeText,
      classes.leftContainer,
      classes.winnerAvatar,
      classes.winnerBorder,
      classes.winnerCrown,
      classes.winnerName,
      classes.winnerRow,
      getMediaSource,
      winners.length,
    ],
  );

  return (
    <div className={classes.container}>
      {winnersListHeader && <LabelText className={classes.headerText} text={winnersListHeader} isHTML />}
      {winners && <div className={classes.winnersList}>{winners.map(renderWinner)}</div>}
      {notAllPrizesFooter && <LabelText text={notAllPrizesFooter} />}
      {winnersListFooter && <LabelText text={winnersListFooter} />}
    </div>
  );
});
