import { useCallback } from 'react';

import { Key, KeyTitlesMapper, useKeyboardEventListener } from 'src/components/Moderation/utils/keyboard';
import { ActionsLogger } from 'src/components/Moderation/utils/actions-logger';
import { ModerationMode } from '../provider/types';

type Props = {
  approveAction: () => void;
  moderationMode: ModerationMode | null;
  setModerationMode: (mode: ModerationMode | null) => void;
};

export const useKeyListener = (props: Props) => {
  const { moderationMode, approveAction, setModerationMode } = props;

  const keyPressListener = useCallback(
    (event: KeyboardEvent) => {
      if (!event.defaultPrevented) {
        ActionsLogger.addKeyCode(KeyTitlesMapper[event.keyCode]);

        switch (event.keyCode) {
          case Key.Space:
            if (moderationMode === null) {
              approveAction();
            }
            break;
          case Key.One:
            if (moderationMode === null) {
              setModerationMode(ModerationMode.ChangeGroup);
            }
            break;
          case Key.Escape:
            if (moderationMode === ModerationMode.History || moderationMode === ModerationMode.ChangeGroup) {
              setModerationMode(null);
            }
            break;
          default:
            break;
        }
      }
      return undefined;
    },
    [approveAction, moderationMode, setModerationMode],
  );

  useKeyboardEventListener(keyPressListener);
};
