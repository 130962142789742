import { makeStyles } from '@material-ui/core';

const avatarSize = 50;
const largeAvatarSize = 60;

const chatAvatarWidth = 72;
export const chatAvatarSize = `.${2 * chatAvatarWidth}x${2 * chatAvatarWidth}`;

export const useStyles = makeStyles({
  container: {
    padding: '16px',
  },

  headerText: {
    display: 'block',
    marginBottom: '16px',
  },

  winnersList: {
    marginBottom: '14px',
  },

  winnerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
  },

  winnerBorder: {
    borderBottom: '1px solid rgba(60, 60, 67, 0.36)',
  },

  leftContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '8px',
    maxWidth: '220px',
  },

  winnerCrown: {
    position: 'absolute',
    top: '-25px',
    left: '-14px',
    width: '43px',
    height: '39px',
    resizeMode: 'contain',
  },

  winnerAvatar: {
    width: `${avatarSize}px`,
    height: `${avatarSize}px`,
    borderRadius: `${avatarSize / 2}px`,
  },

  grandWinnerAvatar: {
    width: `${largeAvatarSize}px`,
    height: `${largeAvatarSize}px`,
    borderRadius: `${largeAvatarSize / 2}px`,
  },

  winnerName: {
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '23px',
  },

  benefitTextRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },

  largeText: {
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '30px',
  },

  emoji: {
    marginBottom: '1px',
  },

  emojiMargin: {
    marginBottom: '3px',
  },
});
