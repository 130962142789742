import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

import { VerificationTag } from 'src/types/verification';
import { verificationRequest } from 'src/network/verification';
import { userRequest } from 'src/network';
import { noop } from 'src/utils/functions';

import { useStyles } from './styles';

type Props = {
  userId: string;
  mediaCount?: number;
  updateHandledUsersCount: () => void;
  fetchContentCount: () => void;
  loadNextUser: () => void;
};

export const VerificationActionButtons: React.FC<Props> = (props) => {
  const { userId: userIdFromProps, mediaCount, updateHandledUsersCount, fetchContentCount, loadNextUser } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reasonHeader = { 'X-Reason': `photos=${mediaCount || 0}` };

  const submit = useCallback(
    async (userId: string, tag: VerificationTag, msg: string, headers: Record<string, string>) => {
      try {
        await verificationRequest.sendUserTags(userId, tag, headers);
        userRequest.putUserVerifiedByModeratorTag(userId).catch(noop);
        enqueueSnackbar(msg, { variant: 'success' });
        updateHandledUsersCount();
        fetchContentCount();
        await loadNextUser();
      } catch (e) {
        enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
      }
    },
    [], // eslint-disable-line
  );

  const scamUser = useCallback(async (userId: string, headers: Record<string, string>) => {
    try {
      await verificationRequest.scamUser(userId, 'Verification photo', headers);
      enqueueSnackbar('User marked as SCAM', { variant: 'success' });
      await verificationRequest.sendUserTags(userId, [], headers);
      updateHandledUsersCount();
      fetchContentCount();
      await loadNextUser();
    } catch (e) {
      enqueueSnackbar(e.toString() || 'Bad request', { variant: 'error' });
    }
  }, []); // eslint-disable-line

  return (
    <div className={classes.buttonsContainer}>
      <Button
        onClick={() => submit(userIdFromProps, VerificationTag.Verified, 'User Approved', reasonHeader)}
        className={clsx(classes.btn, classes.btnApprove)}
        variant="contained"
      >
        Approve
      </Button>
      <Button
        onClick={() => submit(userIdFromProps, VerificationTag.Required, 'User Declined', reasonHeader)}
        className={clsx(classes.btn, classes.btnDecline)}
        variant="contained"
      >
        Decline
      </Button>
      <Button
        onClick={() => scamUser(userIdFromProps, reasonHeader)}
        className={clsx(classes.btn, classes.btnScam)}
        variant="contained"
      >
        Scam
      </Button>
    </div>
  );
};
