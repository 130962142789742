import React from 'react';
import { CircularProgress } from '@material-ui/core';

import { Layout } from 'src/components/App/views/Layout';

import { UserModerationHistory } from './components/user-moderation/history';
import { UserModerationProfile } from './components/user-moderation/profile';
import { UserModerationChangeGroup } from './components/user-moderation/change-group';
import { ModerationProvider } from './provider';
import { useCloseAndReload, useDataContext, useActionsContext, useKeyListener } from './hooks';
import { ModerationMode } from './provider/types';
import { useStyles } from './styles';

const ScreenLayout = () => {
  const { processing, isQueueLoading, moderationMode } = useDataContext();
  const { onSave, setModerationMode } = useActionsContext();

  const showModerationProfile = !isQueueLoading && moderationMode === null;
  const showModerationHistory = !isQueueLoading && moderationMode === ModerationMode.History;
  const showModerationChangeGroup = !isQueueLoading && moderationMode === ModerationMode.ChangeGroup;
  const classes = useStyles({ hasBottomPanel: showModerationChangeGroup || showModerationProfile });

  useCloseAndReload();
  useKeyListener({
    approveAction: onSave,
    moderationMode,
    setModerationMode,
  });

  return (
    <Layout containerSize="lg">
      <div className={classes.container}>
        {isQueueLoading && !processing && (
          <div className={classes.placeholderContainer}>
            <CircularProgress color="secondary" className={classes.progress} />
          </div>
        )}

        {showModerationProfile && <UserModerationProfile />}
        {showModerationHistory && <UserModerationHistory />}
        {showModerationChangeGroup && <UserModerationChangeGroup />}

        {processing && (
          <div className={classes.processingContainer}>
            <CircularProgress color="secondary" className={classes.progress} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export const UserModerationOnceScreen = () => {
  return (
    <ModerationProvider>
      <ScreenLayout />
    </ModerationProvider>
  );
};
