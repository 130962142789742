import { Gender } from 'src/types/user';
import { DailyDropBenefit } from 'src/network/flure-daily-drop/types';

export type FlureChatWinner = {
  userId: string;
  name: string;
  gender: Gender;
  type: DailyDropBenefit;
  thumbnail: string;
};

export type MessageMeta = {
  buttonTitle?: string;
  buttonUrl?: string;
  componentsOrder?: MessageComponents[];
  videoDuration?: number;
  winnersListHeader?: string;
  winnersListFooter?: string;
  notAllPrizesFooter?: string;
  winners?: FlureChatWinner[];
};

export type MessagePreview = {
  id?: number;
  text?: string;
  reference?: string;
  created?: string;
  meta: MessageMeta;
};

export enum MessageComponents {
  Text = 'Text',
  Photo = 'Photo',
  Video = 'Video',
  Button = 'Button',
  WinnersList = 'WinnersList',
}

export const defaultComponentsOrder = Object.values(MessageComponents);

export const defaultMessageComponentsState = {
  [MessageComponents.Text]: false,
  [MessageComponents.Photo]: false,
  [MessageComponents.Video]: false,
  [MessageComponents.Button]: false,
  [MessageComponents.WinnersList]: false,
};
