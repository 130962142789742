import React, { useCallback, useEffect, useMemo, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useSnackbar } from 'notistack';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { CircularProgress } from '@material-ui/core';

import { flureDailyDrop } from 'src/network/flure-daily-drop';
import { DailyDropBenefit, DailyDropResult } from 'src/network/flure-daily-drop/types';

import copyIcon from './copy-icon.svg';
import { Layout } from '../App/views/Layout';
import { TitleText } from '../common/flure';
import { useStyles } from './styles';

const benefitTitleMap = {
  [DailyDropBenefit.FiveHundredUsd]: '$500',
  [DailyDropBenefit.OneHundredUsd]: '$100',
  [DailyDropBenefit.FiftyUsd]: '$50',
  [DailyDropBenefit.TenUSDOnboard]: '$10',
  [DailyDropBenefit.Vibes]: '100 Vibes',
};

const benefitEmojiMap = {
  [DailyDropBenefit.FiveHundredUsd]: ' 🏆',
  [DailyDropBenefit.OneHundredUsd]: ' 💰',
  [DailyDropBenefit.FiftyUsd]: ' 💸',
  [DailyDropBenefit.TenUSDOnboard]: ' 💸',
  [DailyDropBenefit.Vibes]: ' 💫',
};

const tableThTitles = ['User Id', 'Email', 'Prize', 'Claim Pressed'];

const dateFormat = 'yyyy-MM-dd';
export const FlureDailyDropResultsScreen = () => {
  const [date, setDate] = useState(new Date());
  const [users, setUsers] = useState<DailyDropResult[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dateFormatted = format(date, dateFormat);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { allEmails, allUserIds } = useMemo(
    () => ({ allEmails: users.map((it) => it.email).join('\n'), allUserIds: users.map((it) => it.userId).join('\n') }),
    [users],
  );

  const copyFieldData = useCallback(
    (text: string) => {
      copy(text);
      enqueueSnackbar('Copied to Clipboard', { variant: 'success' });
    },
    [enqueueSnackbar],
  );

  const renderCopyButton = useCallback(
    (textToCopy: string) => {
      return (
        <button type="button" className={classes.smallButton} onClick={() => copyFieldData(textToCopy)}>
          <img className={classes.smallButtonIcon} src={copyIcon} alt="copy ID" />
        </button>
      );
    },
    [classes.smallButton, classes.smallButtonIcon, copyFieldData],
  );

  const renderUser = useCallback(
    (user: DailyDropResult) => {
      return (
        <tr key={user.userId}>
          <td>
            <div className={classes.row}>
              {renderCopyButton(user.userId)}
              <span>{user.userId}</span>
            </div>
          </td>
          <td>
            <div className={classes.row}>
              {renderCopyButton(user.email)}
              <span>{user.email}</span>
            </div>
          </td>
          <td>
            {benefitTitleMap[user.type]}
            {benefitEmojiMap[user.type]}
          </td>
          <td>{user.received ? '✅' : '❌'}</td>
        </tr>
      );
    },
    [classes.row, renderCopyButton],
  );

  const handleDateChange = useCallback((day: Date) => {
    setDate(day);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    flureDailyDrop.getDailyDropResults(`${dateFormatted}T00:00:00.000Z`).then((response) => {
      setIsLoading(false);
      setUsers(response.result || []);
    });
  }, [dateFormatted]);

  return (
    <Layout containerSize="lg">
      <div className={classes.container}>
        <div className={classes.topRow}>
          <TitleText text="Daily Drop Winners" />
          <DatePicker
            wrapperClassName={classes.datePickerWrapper}
            customInput={<input className={classes.datePickerInput} />}
            dateFormat={dateFormat}
            selected={new Date(date)}
            onChange={handleDateChange}
          />
        </div>
        <table className={classes.table}>
          <thead>
            <tr>
              {tableThTitles.map((field) => (
                <th key={field}>
                  <div className={classes.row}>
                    {field}
                    {field === 'User Id' && allUserIds.length ? renderCopyButton(allUserIds) : null}
                    {field === 'Email' && allEmails.length ? renderCopyButton(allEmails) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr className={classes.loadingTr}>
                <td>
                  <CircularProgress className={classes.loader} />
                </td>
              </tr>
            )}
            {!isLoading && users.length === 0 && (
              <tr className={classes.emptyResultsTr}>
                <td colSpan={tableThTitles.length}>No Winners Found</td>
              </tr>
            )}
            {users.map((user) => renderUser(user))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
};
